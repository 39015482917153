/* eslint-disable autofix/no-console */
import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Router from './routers/Routers';

import { MatomoProvider } from './hooks/useMatomoTracking/useMatomoTracking';
import { queryClientConfig } from './config/reactQuery';

import useTabTracking from './hooks/useTabTracking';

// CSS imports
import '@fontsource/open-sans';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';
import '@fontsource/open-sans/300-italic.css';
import '@fontsource/open-sans/400-italic.css';

import 'react-toastify/dist/ReactToastify.css';
import './theme/theme.scss';
import './assets/icons/icons.scss';

const queryClient = new QueryClient(queryClientConfig);

const App: React.FC = () => {
  useTabTracking(() => {
    console.log('🚀 All tabs closed! Performing cleanup...');
    // window.location.href = `${window.location.origin}/logout`;
    // You can call an API, clear session storage, or perform other cleanup tasks
  });

  return (
    <QueryClientProvider client={queryClient}>
      <MatomoProvider>
        <BrowserRouter basename={import.meta.env.BASE_URL}>
          <Router />

          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover
            theme="dark"
          />
        </BrowserRouter>
      </MatomoProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default App;

import { lazy } from 'react';
import { Routes, Route, Navigate, generatePath } from 'react-router-dom';

import Loadable from '../utility/Loadable';

// Layout imports
import BaseLayout from '../layouts/baseLayout/BaseLayout';

const AuthLayout = Loadable(lazy(() => import('../layouts/authLayout/AuthLayout')));
const SimpleLayout = Loadable(lazy(() => import('../layouts/simpleLayout/SimpleLayout')));
const PrivateLayout = Loadable(lazy(() => import('../layouts/privateLayout/PrivateLayout')));

// New template managet layout

const TemplateBaseLayout = Loadable(lazy(() => import('../layouts/TemplateBaseLayout/TemplateBaseLayout')));
const TemplateListLayout = Loadable(lazy(() => import('../layouts/TemplateListLayout/TemplateListLayout')));

// Page imports
const Homepage = Loadable(lazy(() => import('../pages/Homepage/Homepage')));
const Boards = Loadable(lazy(() => import('../pages/BoardsOverview/Boards')));
const Project = Loadable(lazy(() => import('../pages/Project/Project')));
const Archive = Loadable(lazy(() => import('../pages/Archive/Archive')));
const Contacts = Loadable(lazy(() => import('../pages/Contacts/Contacts')));

const Overview = Loadable(lazy(() => import('../pages/Account/Overview/Overview')));
const TeamSettings = Loadable(lazy(() => import('../pages/Account/TeamSettings/TeamSettings')));

// New template page components
const CCBTemplates = Loadable(lazy(() => import('../pages/TemplateManager/CCBTemplates/CCBTemplates')));
const TeamTemplates = Loadable(lazy(() => import('../pages/TemplateManager/TeamTemplates/TeamTemplates')));
const TemplateDetails = Loadable(lazy(() => import('../pages/TemplateManager/TemplateDetails/TemplateDetails')));

// To be migrated to React query
const TeamTemplateForm = Loadable(lazy(() => import('../pages/TeamTemplateForm/TeamTemplateForm')));
const TemplateNotFound = Loadable(lazy(() => import('../pages/TemplateManager/ErrorMessage/ErrorMessage')));

// Extra pages
import ThanksForWorkingWithUs from '../pages/ThanksForWorkingWithUs/ThanksForWorkingWithUs';
import NotFound from '../pages/NotFound/NotFound';
import ErrorPage from '../pages/ErrorPage/ErrorPage';
import AutoLogout from '../pages/AutoLogout/AutoLogout';

import { AppRoutes } from '../constants/AppRoutes';

// TODO: move it to the PrivateLayout once the TeamSettings sidebar is done
import i18n from '../translations/i18n';

import { en } from '../features/shared/components/notificationComponents/translations/en';
import { de } from '../features/shared/components/notificationComponents/translations/de';

i18n.addResourceBundle('en', 'notifications', en);
i18n.addResourceBundle('de', 'notifications', de);

const isDevMode = import.meta.env.REACT_APP_ENVIRONMENT === 'development';

const Routers = () => {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route element={<AuthLayout />}>
          <Route index element={<Navigate to={`${AppRoutes.TEMPLATE}${window.location.search}`} replace />} />
          <Route path={AppRoutes.TEMPLATE} element={<TemplateBaseLayout />}>
            <Route
              index
              element={<Navigate to={`${generatePath(AppRoutes.CCB_TEMPLATES)}${window.location.search}`} replace />}
            />

            <Route element={<TemplateListLayout />}>
              <Route path={AppRoutes.CCB_TEMPLATES} element={<CCBTemplates />} />
              <Route path={AppRoutes.TEAM_TEMPLATES} element={<TeamTemplates />} />
            </Route>

            <Route path={AppRoutes.TEMPLATE_DETAILS} element={<TemplateDetails />} />

            <Route path="*" element={<TemplateNotFound />} />
          </Route>

          <Route path={AppRoutes.TEAM_TEMPLATE_FORM}>
            <Route index element={<TeamTemplateForm />} />
          </Route>

          <Route element={<PrivateLayout />}>
            <Route path={AppRoutes.HOME} element={<Homepage />} />
            <Route path={AppRoutes.BOARDS} element={<Boards />} />
            <Route path={AppRoutes.PROJECT} element={<Project />} />

            <Route path={AppRoutes.ARCHIVE}>
              <Route index element={<Archive />} />
              <Route path={AppRoutes.ARCHIVE_PROJECT} element={<Project />} />
            </Route>

            <Route path={`${AppRoutes.CONTACTS}/:type?/:filter?`} element={<Contacts />} />

            <Route path={AppRoutes.ACCOUNT}>
              <Route
                index
                element={isDevMode ? <Overview /> : <Navigate to={AppRoutes.ACCOUNT_TEAM_SETTINGS} replace />}
              />
              <Route path={AppRoutes.ACCOUNT_TEAM_SETTINGS} element={<TeamSettings />} />
            </Route>
          </Route>

          <Route element={<SimpleLayout />}>
            <Route path={AppRoutes.THANKS_FOR_WORKING_WITH_US} element={<ThanksForWorkingWithUs />} />
          </Route>

          <Route path={'/autologout'} element={<AutoLogout />} />
        </Route>

        {/* Open routes, no auth required (No token) */}
        <Route element={<SimpleLayout />}>
          <Route path={AppRoutes.ERROR} element={<ErrorPage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default Routers;

import React from 'react';
import Box from '@mui/material/Box';

import { PageLoader } from '../../features/shared/components/contentLoader/page/PageLoader';

import useUserInfoQuery from '../../hooks/queries/useUserInfoQuery';
import useAutoLogout from '../../hooks/useAutoLogout';

const AutoLogout: React.FC = () => {
  const { showWarning } = useAutoLogout();

  const { isLoading: isLoadingUser } = useUserInfoQuery({
    enabled: true,
  });

  if (isLoadingUser) return <PageLoader />;

  return (
    <Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: '100vh' }}>
        {`showWarning: ${showWarning}`}
      </Box>
    </Box>
  );
};

export default AutoLogout;

import { useQuery } from '@tanstack/react-query';
import { getLegacyMyCompany } from '../../api/apiRoutes/legacyRoutes';
import useUserInfoQuery from './useUserInfoQuery';
import queryKeys from '../../constants/queryKeys';
import useLegacyUserInfoQuery from './useLegacyUserInfoQuery';

const useLegacyMyCompanyQuery = () => {
  const { data: user } = useUserInfoQuery();
  const { data: legacyUser } = useLegacyUserInfoQuery();

  const isFreeUser = legacyUser?.info?.accountType?.basic ?? true;

  return useQuery({
    queryKey: [queryKeys.MY_COMPANY_LEGACY],
    enabled: !!user?.userId && !isFreeUser,
    queryFn: getLegacyMyCompany,
  });
};

export default useLegacyMyCompanyQuery;

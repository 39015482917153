export default {
  title: 'Fehler',
  headline: 'Oups…',
  message: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
  links: {
    home: {
      text: 'Sie können auch <0>zurück zur Startseite</0> gehen.',
      url: '/',
      title: 'Zurück zur Startseite',
    },
  },
  multierror: {
    headline: 'Es ist ein sich wiederholender Fehler?',
    message:
      'Falls das Problem zum wiederholten Mal auftritt, können Sie uns helfen es zu lösen. Bitte beschreiben Sie kurz, was Sie gerade taten als diese Seite angezeigt wurde. Vielen Dank!',
  },
};

export default {
  title: 'Danke für Ihre Mitarbeit',
  headline: 'Danke für Ihre Mitarbeit!',
  message:
    'Falls Sie Probleme mit Conceptboard hatten oder Verbesserungsvorschläge haben, bitte <0>besuchen Sie die FAQs</0> oder <1>geben Sie Feedback</1>.',
  links: {
    faq: {
      url: 'https://help.conceptboard.com/hc/de',
      title: 'FAQs ansehen',
    },
    feedback: {
      url: 'https://help.conceptboard.com/hc/de/requests/new',
      title: 'Feedback geben',
    },
  },
};

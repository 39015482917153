import { useQuery } from '@tanstack/react-query';
import { getUserCustomSessionExpiry } from '../../api/apiRoutes/legacyRoutes';
import useUserInfoQuery from './useUserInfoQuery';
import queryKeys from '../../constants/queryKeys';

const useUserCustomSessionExpiryQuery = () => {
  const { data: user } = useUserInfoQuery();

  return useQuery({
    queryKey: [queryKeys.USER_CUSTOM_SESSION_EXPIRY],
    enabled: !!user?.userId,
    queryFn: () => getUserCustomSessionExpiry(user?.userId),
  });
};

export default useUserCustomSessionExpiryQuery;

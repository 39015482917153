export const AppRoutes = Object.freeze({
  LOGIN: '/login',
  HOME: '/home',
  BOARDS: '/boards',
  PROJECT: '/project/:projectId',
  ARCHIVE: '/archive',
  ARCHIVE_PROJECT: 'project/:projectId',
  CONTACTS: '/contacts',
  ACCOUNT: '/account',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_PLAN: '/account/plan',
  ACCOUNT_INVOICES: '/account/invoices',
  ACCOUNT_TEAM_SETTINGS: '/account/team',
  ACCOUNT_MANAGELICENSE: '/account/managelicense',
  ACCOUNT_SSO: '/account/sso',
  ERROR: '/error',
  TEMPLATE: '/template-manager',
  CCB_TEMPLATES: 'ccb-templates/:categoryId?',
  TEAM_TEMPLATES: 'team-templates',
  TEMPLATES: '/templates',
  TEMPLATE_DETAILS: 'details/:templateId',
  TEAM_TEMPLATE_FORM: '/teamtemplates/new',
  THANKS_FOR_WORKING_WITH_US: '/thanks-for-working-with-us',
});

export type AppRouteValues = (typeof AppRoutes)[keyof typeof AppRoutes];

// Routes that are used inside React-Router, others are redirect to Webapp
export const InternalRoutes: AppRouteValues[] = [AppRoutes.HOME, AppRoutes.BOARDS, AppRoutes.ARCHIVE];

import React from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import useLegacyMyCompanyQuery from '../../hooks/queries/useLegacyMyCompanyQuery';
import DocumentTitle from '../../features/shared/components/DocumentTitle';
import i18n from '../../translations/i18n';
import en from './translations/en';
import de from './translations/de';

i18n.addResourceBundle('en', 'thanksforusing', en);
i18n.addResourceBundle('de', 'thanksforusing', de);

const TitleText = styled(Typography)<TypographyProps>(() => ({
  fontSize: '21px',
  fontWeight: '600',
  marginBottom: '24px',
  color: '#404040',
}));

const Message = styled(Typography)<TypographyProps>(() => ({
  fontSize: '14px',
  marginBottom: '24px',
  color: '#404040',
  textAlign: 'center',
}));

const StyledLink = styled(Link)`
  color: #067bc5;
  text-decoration: underline;

  &:hover {
    color: #067bc5;
    text-decoration: underline;
  }
`;

const StyledImage = styled('img')`
  vertical-align: middle;
  max-height: 40px;
`;

const ThanksForWorkingWithUs: React.FC = () => {
  const { t } = useTranslation('thanksforusing');

  const { data: company } = useLegacyMyCompanyQuery();

  const getCompanyBranding = () => {
    if (company?.companylogoEtags?.welcomebanner) {
      return (
        <Typography variant="h1" margin={0}>
          <StyledImage
            alt={company?.name}
            src={'/__/companys/' + company?.id + '/logos/welcomebanner.png.' + company?.companylogoEtags?.welcomebanner}
          />
        </Typography>
      );
    } else if (company?.companylogoEtags?.standard) {
      return (
        <Typography variant="h1" margin={0}>
          <StyledImage
            alt={company?.name}
            src={'/__/companys/' + company?.id + '/logos/standard.png.' + company?.companylogoEtags?.standard}
          />
        </Typography>
      );
    } else {
      return (
        <Typography
          sx={{
            flexGrow: 0,
            margin: 0,
            fontSize: '24px',
            display: { xs: 'none', md: 'block' },
            marginBottom: '24px',
            fontWeight: 'bold',
          }}
          data-testid="header-title-container"
          id="header-label"
        >
          {company?.name || ''}
        </Typography>
      );
    }
  };

  return (
    <>
      <DocumentTitle title={t('title')} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {getCompanyBranding()}

        <TitleText variant="h4" component={'h1'}>
          {t('headline')}
        </TitleText>

        <Message variant="body1">
          <Trans
            defaults={t('message')}
            components={[
              <StyledLink
                to={t('links.faq.url')}
                key={'link1'}
                target="_blank"
                title={t('links.faq.title')}
              ></StyledLink>,
              <StyledLink to={t('links.feedback.url')} key={'link2'} title={t('links.feedback.title')}></StyledLink>,
            ]}
          />
        </Message>
      </Box>
    </>
  );
};

export default ThanksForWorkingWithUs;

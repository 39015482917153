export default {
  title: 'Thanks for working with us',
  headline: 'Thanks for working with us!',
  message:
    'If you have encountered any problems with Conceptboard or if you have suggestions for improvement, please <0>visit the FAQs</0> or <1>give direct feedback</1>.',
  links: {
    faq: {
      url: 'https://help.conceptboard.com/hc/en-us',
      title: 'visit FAQs',
    },
    feedback: {
      url: 'https://help.conceptboard.com/hc/en-us/requests/new',
      title: 'give feedback',
    },
  },
};

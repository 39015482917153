export default {
  title: 'Unknown page',
  message:
    'We could not find the page you requested. Please check the address bar of your browser. Maybe you have used a link, which is not valid anymore?',
  links: {
    home: {
      text: 'Back to the start page',
      url: '/',
      title: 'Back to the start page',
    },
  },
};

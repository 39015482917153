import { useQuery } from '@tanstack/react-query';
import { getLegacyUserInfo } from '../../api/apiRoutes/legacyRoutes';
import useUserInfoQuery from './useUserInfoQuery';
import queryKeys from '../../constants/queryKeys';

const useLegacyUserInfoQuery = () => {
  const { data: user } = useUserInfoQuery();

  return useQuery({
    queryKey: [queryKeys.USER_INFO_LEGACY],
    enabled: !!user?.userId,
    queryFn: getLegacyUserInfo,
  });
};

export default useLegacyUserInfoQuery;

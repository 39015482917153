/**
 * Get initials from name
 * @param name
 */
export const getInitials = (name: string) => {
  if (!name) return null;

  const nameArray = name.trim().split(' ');
  const firstName = nameArray[0].charAt(0).toUpperCase();
  const lastName = nameArray[nameArray.length - 1].charAt(0).toUpperCase();

  return {
    children: `${firstName + lastName}`,
  };
};

/**
 * Returns true if the board has an unammed board title. Also respects copies of boards.
 */
export const isUnnamedBoard = (title: string): boolean => {
  return /^(?:Copy: |Kopie: )?(?:Unnamed board|New Board|Unbenanntes Board|Neues Board)(?: \((?:v|V)ersion.+)?$/.test(
    title
  );
};

/**
 * Overflow some text with ellipsis (triple dot in the end) given a maximum number of characters
 * @param text
 */
export const makeTextOverflowFromString = (text: string, maxChars: number): string => {
  if (!!text && text.length > maxChars) {
    return text.slice(0, maxChars) + '...';
  }
  return text;
};

export const parseInfoResponse = (text: string) => {
  if (typeof text != 'string') return null;

  if (text.startsWith('{}&&')) {
    // Remove Replace all occurrences of "\'" with just "'"
    const jsonText = text.slice(4).replace(/\\'/g, "'");
    return JSON.parse(jsonText);
  }

  return JSON.parse(text);
};

export const containsAny = (str: string, substrings: string[]): boolean => {
  return substrings.some((sub) => str.includes(sub));
};

export const hasCommonStrings = (array1: string[], array2: string[]): boolean => {
  const set1 = new Set(array1);
  return array2.some((item) => set1.has(item));
};

export const hasSubstring = (str: string, substring: string): boolean => {
  return str.toLowerCase().includes(substring.toLowerCase());
};

export const sortString = (stringA: string, stringB: string) => {
  const nameA = stringA.toLowerCase();
  const nameB = stringB.toLowerCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

const generateTeamTemplateLanguageList = (teamTemplateList: any) => {
  const languageList = teamTemplateList
    .map((template: any) => template.language.code)
    .reduce((acc: string[], curr: string) => {
      if (!acc.includes(curr)) acc.push(curr);
      return acc;
    }, []);

  return languageList;
};

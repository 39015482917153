import { Resource } from '@opentelemetry/resources';
import { SimpleSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { SemanticResourceAttributes } from '@opentelemetry/semantic-conventions';
import { getMetaValueByTagName } from './utility/CommonUtility';

// Fetch environment variables
const METRICS_API = process.env.REACT_APP_METRICS_API_URL
  ? process.env.REACT_APP_METRICS_API_URL
  : getMetaValueByTagName('REACT_APP_METRICS_API_URL');

const OPENTELEMETRY_INSTANCE = process.env.REACT_APP_OPENTELEMETRY_INSTANCE
  ? process.env.REACT_APP_OPENTELEMETRY_INSTANCE
  : getMetaValueByTagName('REACT_APP_OPENTELEMETRY_INSTANCE');

const REACT_APP_OTEL_EXPORTER_OTLP_ENABLED = process.env.REACT_APP_OTEL_EXPORTER_OTLP_ENABLED
  ? process.env.REACT_APP_OTEL_EXPORTER_OTLP_ENABLED !== 'false'
  : getMetaValueByTagName('REACT_APP_OTEL_EXPORTER_OTLP_ENABLED') === 'true';

// Collector options for OTLP exporter
const collectorOptions = {
  url: `${METRICS_API}/v1/traces`,
  headers: {
    'Content-Type': 'application/json',
  },
};

// Initialize OpenTelemetry provider with configuration
const providerConfig = {
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: 'ccs-app-ui-service',
  }),
};

const initializeInstrumentation = () => {
  const provider = new WebTracerProvider(providerConfig);

  // Set up OTLP Exporter if enabled
  if (REACT_APP_OTEL_EXPORTER_OTLP_ENABLED && METRICS_API) {
    provider.addSpanProcessor(new SimpleSpanProcessor(new OTLPTraceExporter(collectorOptions)));
  }

  // Register context manager and auto-instrumentation
  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        // load custom configuration for xml-http-request instrumentation
        '@opentelemetry/instrumentation-xml-http-request': {
          clearTimingResources: true,
        },
      }),
    ],
  });

  // Helper function to capture errors and send them to OpenTelemetry
  function reportErrorToOpenTelemetry(errorArgs: any) {
    const error = errorArgs[0];
    const tracer = provider.getTracer('ccs-app-ui-service', '1.0.0');
    const span = tracer.startSpan('console.error', {
      attributes: {
        'error.message': error.message || error,
        'error.stack': error.stack || 'No stack trace available',
        'service.name': OPENTELEMETRY_INSTANCE || 'react-app', // Tag with instance name
        error: true,
      },
    });
    span.end();
  }

  // Capture window errors and unhandled rejections
  window.onerror = (message, source, lineno, colno, error) => {
    reportErrorToOpenTelemetry([error || message]);
  };

  window.onunhandledrejection = (event) => {
    reportErrorToOpenTelemetry([event.reason || 'Unhandled promise rejection']);
  };
};

export default initializeInstrumentation;

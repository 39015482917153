import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { onCLS, onINP, onLCP } from 'web-vitals';
import initializeInstrumentation from './instrumentation';
import { GeneralHelper } from './constants/GeneralHelper';

initializeInstrumentation();

// eslint-disable-next-line autofix/no-console
console.log(
  '%c\n\n ██████╗ ██████╗ ███╗   ██╗ ██████╗███████╗██████╗ ████████╗██████╗  ██████╗  █████╗ ██████╗ ██████╗ \n██╔════╝██╔═══██╗████╗  ██║██╔════╝██╔════╝██╔══██╗╚══██╔══╝██╔══██╗██╔═══██╗██╔══██╗██╔══██╗██╔══██╗\n██║     ██║   ██║██╔██╗ ██║██║     █████╗  ██████╔╝   ██║   ██████╔╝██║   ██║███████║██████╔╝██║  ██║\n██║     ██║   ██║██║╚██╗██║██║     ██╔══╝  ██╔═══╝    ██║   ██╔══██╗██║   ██║██╔══██║██╔══██╗██║  ██║\n╚██████╗╚██████╔╝██║ ╚████║╚██████╗███████╗██║        ██║   ██████╔╝╚██████╔╝██║  ██║██║  ██║██████╔╝\n ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝ ╚═════╝╚══════╝╚═╝        ╚═╝   ╚═════╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝╚═════╝ \n\n',
  'font-family:monospace;color:#1976d2;font-size:10px;line-height:10px;'
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

if (GeneralHelper.APP_INSTANCE != 'integration') {
  const sendToAnalytics = (metric: any) => {
    const body = JSON.stringify(metric);
    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
    (navigator.sendBeacon && navigator.sendBeacon('/analytics', body)) ||
      fetch('/analytics', { body, method: 'POST', keepalive: true });
  };

  onCLS(sendToAnalytics);
  onINP(sendToAnalytics);
  onLCP(sendToAnalytics);
}

export default {
  title: 'Unbekannte Adresse',
  message:
    'Die angefragte Seite existiert nicht. Bitte überprüfen Sie die Adresszeile Ihres Browsers. Vielleicht haben Sie einen Link genutzt, der nicht mehr gültig ist?',
  links: {
    home: {
      text: 'Zurück zur Startseite',
      url: '/',
      title: 'Zurück zur Startseite',
    },
  },
};

import { getMetaValueByTagName } from '../utility/CommonUtility';

export const GeneralHelper = Object.freeze({
  APP_CONTENT: 'Template',
  APP_ENVIRONMENT:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_ENVIRONMENT
      ? import.meta.env.REACT_APP_ENVIRONMENT
      : getMetaValueByTagName('ENVIRONMENT'),
  APP_TITLE:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_APP_TITLE
      ? import.meta.env.REACT_APP_APP_TITLE
      : getMetaValueByTagName('APP_TITLE'),
  APP_URL:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_APP_URL
      ? import.meta.env.REACT_APP_APP_URL
      : getMetaValueByTagName('APP_URL'),
  API_URL:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_TEMPLATE_API_URL
      ? import.meta.env.REACT_APP_TEMPLATE_API_URL
      : getMetaValueByTagName('APP_API_URL'),
  MATOMO_SITE_ID:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_MATOMO_SITE_ID
      ? import.meta.env.REACT_APP_MATOMO_SITE_ID
      : getMetaValueByTagName('MATOMO_SITE_ID'),
  MATOMO_URL:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_MATOMO_URL
      ? import.meta.env.REACT_APP_MATOMO_URL
      : getMetaValueByTagName('MATOMO_URL'),
  APP_INSTANCE:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_INSTANCE
      ? import.meta.env.REACT_APP_INSTANCE
      : getMetaValueByTagName('REACT_APP_INSTANCE'),
  MAX_IMAGE_SIZE:
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_MAX_IMAGE_SIZE
      ? import.meta.env.REACT_APP_MAX_IMAGE_SIZE
      : getMetaValueByTagName('MAX_IMAGE_SIZE'),
  AVATAR_SIZES: {
    SMALL: 36,
    MEDIUM: 76,
    LARGE: 186,
  },
  APP_DRAWER_WIDTH: 250,
  FORM_FIELD_CONTAINER_WIDTH: 640,
  EN_LANG: 'en-GB',
  DE_LANG: 'de-DE',
});

import { QueryCache } from '@tanstack/react-query';
import { isAxiosError } from 'axios';

import { showAlert } from '../utility/ToasterUtility';
import { parseInfoResponse } from '../utility/StringUtility';

const TWENTY_SECONDS = 1000 * 20;

export const queryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: TWENTY_SECONDS,
      retry(failureCount: number, error: Error) {
        if (isAxiosError(error)) {
          // Handle 403 error on the first retry
          if (failureCount === 0 && error?.response?.status === 403) {
            // TODO: add toast notification
            // The solution could also be to toast notification "Reload page" to set a new CSRF token
            // If that fails, go to /login
            window.location.href = `${window.location.origin}/login?afterwardsUrl=${window.location.pathname}`;

            return false;
          }
        }

        return true;
      },
    },
    mutations: {
      retry(failureCount: number, error: Error) {
        if (isAxiosError(error)) {
          // Handle 403 error on the first retry

          if (failureCount === 0 && error?.response?.status === 403) {
            const errorResponse = parseInfoResponse(error?.response?.data);

            if (errorResponse.error === 'ajax.noaccess') {
              showAlert('warning', errorResponse.msgs[0].detail, { position: 'bottom-left' });
            } else {
              window.location.href = `${window.location.origin}/login?afterwardsUrl=${window.location.pathname}`;
            }

            return false;
          }
        }
        return true;
      },
    },
  },

  queryCache: new QueryCache({
    onError: (error: Error) => {
      if (isAxiosError(error)) {
        // TODO: add error handling for other statuses (toast notification)
      }
    },
  }),
};

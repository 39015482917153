/* eslint-disable react/display-name */
import { Suspense, FC, LazyExoticComponent } from 'react';

// project import
import { PageLoader } from '../features/shared/components/contentLoader/page/PageLoader';

const Loadable =
  (Component: LazyExoticComponent<FC>): FC =>
  (props) => (
    <Suspense fallback={<PageLoader />}>
      <Component {...props} />
    </Suspense>
  );

export default Loadable;

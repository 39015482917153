import { FC, useEffect, useContext, createContext, PropsWithChildren } from 'react';

import { GeneralHelper } from '../../constants/GeneralHelper';

declare global {
  interface Window {
    _paq: any;
  }
}

export interface IMatomoContext {
  trackPageView: any;
}

export interface TrackPageViewParams {
  documentTitle?: string;
  href?: string | Location;
  customDimensions?: boolean | CustomDimension[];
}

export interface CustomDimension {
  id: number;
  value: string;
}

export const MatomoContext = createContext<IMatomoContext>({} as IMatomoContext);

export const MatomoProvider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    if (
      GeneralHelper.APP_ENVIRONMENT !== 'development' &&
      !!GeneralHelper.MATOMO_URL &&
      !!GeneralHelper.MATOMO_SITE_ID
    ) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.defer = true;
      script.src = `${GeneralHelper.MATOMO_URL}/matomo.js`;
      document.body.appendChild(script);

      window._paq = window._paq || [];
      window._paq.push(['disableCookies', false]);
      window._paq.push(['setCookieDomain', '*.conceptboard.com']);
      window._paq.push(['setDomains', '*.conceptboard.com']);
      window._paq.push(['enableCrossDomainLinking']);
      window._paq.push(['setDoNotTrack', true]);

      window._paq.push(['setTrackerUrl', `${GeneralHelper.MATOMO_URL}/matomo.php`]);
      window._paq.push(['setSiteId', GeneralHelper.MATOMO_SITE_ID]);
    }
  }, []);

  const trackPageView = (params?: TrackPageViewParams) => {
    if (window._paq) {
      if (params?.documentTitle) window._paq.push(['setDocumentTitle', params.documentTitle]);
      if (params?.href) window._paq.push(['setCustomUrl', params.href]);

      window._paq.push(['trackPageView']);
    }
  };

  return <MatomoContext.Provider value={{ trackPageView }}>{children}</MatomoContext.Provider>;
};

export const useMatomoTracking = () => {
  return useContext(MatomoContext);
};

import orderBy from 'lodash/orderBy';
import dayjs from 'dayjs';

import { KeyboardEvent } from 'react';
import { ITemplate } from '../types/TemplateManager.types';
import { ParticipantType } from '../types/BoardsOverview.types';
import { InfoCallRequestPayloadType } from '../api/api.types';

/**
 * @function getMetaValueByTagName
 * @param name
 * @returns
 */
export const getMetaValueByTagName = (name: string): string | null => {
  const metaValue = document.getElementsByTagName('meta').namedItem(name)?.getAttribute('content');
  if (metaValue === '${' + name + '}') {
    return null;
  }
  return metaValue ?? null;
};

/**
 * decodeJwt
 * @param token
 * @returns token data obj
 */
export const decodeJwt = (token: any) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

/**
 * replace the parameters in the string
 * @param value
 * @param parameters
 * @returns formatted value
 */
export const replaceParameters = (value: string, parameters: any) => {
  for (const param of Object.keys(parameters)) {
    value = value.replace('{'.concat(param).concat('}'), parameters[param]);
  }

  return value;
};

/**
 * Concatenates the template Id and size with URL
 * @param {Template} template - The id of the language specific template
 * @param {number} height - The image height in px
 * @param {number} width - The image width in px
 * @returns {string} - URL of template image according to provided template id and size
 */
export const getTemplateImageURL = (template: ITemplate, width: number, height: number): string => {
  const { templateId, language, isTeamTemplate } = template;

  if (isTeamTemplate) {
    return `/export-teamtemplate/${templateId}/sizes/;height=${height};width=${width}`;
  }

  return `/export-template/${templateId}/${language?.code || 'en'}/sizes/;height=${height};width=${width}`;
};

/**
 * Sort list in ascending or descending order on the basis of provided parameters
 * @param list
 * @param property  object property by which the array to be sorted
 * @param soryBy
 * @returns Sorted version of provided list
 */
export const sortArrayOfObject = (list: any, property: string, soryBy: boolean | 'asc' | 'desc') => {
  return orderBy(list, [property], soryBy);
};

/**
 * Function to set default image source when there is an error in loading image
 * @param e Image event
 */
export const onImageError = (e: any) => {
  e.target.src = '';
};

/**
 * get message string
 * @param string
 * @returns message
 */

export const getMessageString = (string: any) => {
  const message = string.replace(/\{|\}/gi, '').split(':')[2].replace(/['"]/g, '');
  return message;
};

// validate URLs that start with 'http,' 'https,' or 'www.'
export const validateTeamSettingsURL = (value?: string) => {
  if (!value) return true;

  // added www. format as a valid URL
  if (value.startsWith('www.')) {
    value = `https://${value}`;
  }

  if (value.startsWith('http') || value.startsWith('https')) {
    try {
      new URL(value);

      return true;
    } catch {
      return false;
    }
  }

  return false;
};

/**
 * The old way to read cookie values, validate with /users/me, and find the right CSRF token
 * @returns a token or null
 */
export const getCSRFTokenFallback = async () => {
  const cookies = document.cookie.split(';');
  const csrfs = [];

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    if (name === '__Secure-csrf') {
      csrfs.push(value);
    }
  }

  for (const csrfToken of csrfs) {
    try {
      const response = await fetch(`${window.location.origin}/api/v0_1/users/me?format=complexlegacy`, {
        headers: { 'X-CB-CSRF': csrfToken },
      });
      const data = await response.json();

      if (data?.data?.id) {
        return csrfToken;
      }
    } catch (error) {
      console.error('Error verifying CSRF token:', error);
    }
  }

  return null;
};

export const getCSRFTokenFromCookies = async () => {
  const cookies = document.cookie.split(';');
  // TODO: Investigate GeneralHelper meta env data

  const APP_INSTANCE =
    typeof import.meta.env !== 'undefined' && import.meta.env.REACT_APP_INSTANCE
      ? import.meta.env.REACT_APP_INSTANCE
      : getMetaValueByTagName('REACT_APP_INSTANCE');

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=').map((c) => c.trim());
    if (name === `__Secure-${APP_INSTANCE}-csrf`) {
      return value;
    }
  }

  // fallback to CSRF token without instance prefix (env.enterprise in ccs project)
  return getCSRFTokenFallback();
};

/**
 * get CSRF token
 * @returns a token or null
 */
export const getCSRFToken = async (): Promise<string | null> => {
  let csrfToken = await getCSRFTokenFromCookies();

  if (csrfToken) {
    return csrfToken;
  }

  try {
    await fetch(`${window.location.origin}/api/v0_1/users/me?format=complexlegacy`);
    csrfToken = await getCSRFTokenFromCookies();

    return csrfToken;
  } catch (error) {
    console.error('Error verifying CSRF token:', error);
  }

  return null;
};

/**
 * Check if a date string is today
 * @param string
 * @returns boolean
 */
export const isToday = (date: string) => {
  return dayjs(date).isSame(dayjs(), 'day');
};

/**
 * Check if a date string is yesterday
 * @param string
 * @returns boolean
 */
export const isYesterday = (date: string) => {
  return dayjs(date).isSame(dayjs().subtract(1, 'day'), 'day');
};

/**
 * Based on a keyboard key, open or close menu.
 */
export const handleHeaderKeyboardNavigation = (
  e: KeyboardEvent<HTMLElement>,
  handleOpen: () => void,
  handleClose: () => void
) => {
  if (e.key === 'Enter' || e.key === ' ') {
    handleOpen();
  }
  if (e.key === 'Tab' || e.key === 'Escape') {
    handleClose();
  }
};

// TODO: Fix this use of any with appropriate types
export const getUiApprovalStatus = (approval: any) => {
  if (!approval || !approval.status) {
    return 'none';
  }
  switch (approval.status) {
    case 'approved':
      if (
        approval.votes.some((vote: any) => {
          return !!vote.text;
        })
      ) {
        return 'approved_with_changes';
      }
      return 'approved';

    case 'pending':
      return approval.status;
    case 'rejected':
    default:
      return approval.status;
  }
};

export const generateInfoRequestPayload = (participantList: ParticipantType[]): InfoCallRequestPayloadType[] => {
  const userIds = participantList?.map((participant: ParticipantType) => ({
    subject: 'miniuser',
    subjectId: participant.userId,
  }));
  return userIds;
};

/**
 *
 * @param {string} input - A comma separated list of strings
 * @returns  { validEmails: string[]; invalidEmails: string[]; } - An object containing two arrays
 */
export const extractEmailsWithErrors = (input: string): { validEmails: string[]; invalidEmails: string[] } => {
  // Regular expression to validate email addresses
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Split the input string by comma and trim each element
  const elements = input.split(',').map((el) => el.trim());

  // Arrays to hold valid and invalid email addresses
  const validEmails: string[] = [];
  const invalidEmails: string[] = [];

  // Iterate over each element and check if it is a valid email
  elements.forEach((element) => {
    if (element) {
      if (emailRegex.test(element)) {
        validEmails.push(element);
      } else {
        invalidEmails.push(element);
      }
    }
  });

  return { validEmails, invalidEmails };
};

export const safeCopyToClipboard = (text: string): Promise<void> => {
  return navigator.permissions
    .query({ name: 'clipboard-write' as PermissionName })
    .then((permissionStatus) => {
      if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
        return navigator.clipboard.writeText(text);
      } else {
        return Promise.reject(new Error('Clipboard write permission denied'));
      }
    })
    .catch((err) => {
      console.error('Failed to copy text: ', err);
      return Promise.reject(err as Error);
    });
};

export const copyToClipboard = (text: string): Promise<void> => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  } else {
    // Fallback for older browsers (this may not work on all browsers)
    const textArea = document.createElement('textarea');
    textArea.value = text;
    // Prevent scrolling to bottom on iOS devices
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error('Failed to copy text using fallback method'));
      }
    } catch (error) {
      console.error('There was an error:', error);
      return Promise.reject(new Error('Fallback method failed'));
    } finally {
      document.body.removeChild(textArea);
    }
  }
};

/**
 * Gets Json data and triggers a download converting the content to CSV
 * @param data json data
 * @param filename string
 */
export const downloadCSV = (csvData: any, filename: string) => {
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

// Parse Yahoo Elide response from /userpreferences
export const parseElideResponse = (data: any) =>
  data.reduce((acc: any, prop: any) => {
    const value = prop.attributes.value;
    let parsedValue;

    if (value === 'true' || value === 'false') {
      parsedValue = value === 'true';
    } else {
      // Remove "", for example value = ""visible""
      parsedValue = value.slice(1, -1);
    }
    acc[prop.attributes.key] = parsedValue;

    return acc;
  }, {});

export const isPageInIframe = () => {
  let inIframe = false;
  try {
    inIframe = window.self !== window.top;
  } catch (e) {
    inIframe = true; // Access denied, assume it's in an iframe
  }

  return inIframe;
};

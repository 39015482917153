import { useQuery } from '@tanstack/react-query';
import { getDocumentCustomSessionExpiry } from '../../api/apiRoutes/legacyRoutes';

import queryKeys from '../../constants/queryKeys';

const useDocumentCustomSessionExpiryQuery = ({ documentId }: { documentId: string | null }) => {
  return useQuery({
    queryKey: [queryKeys.DOCUMENT_CUSTOM_SESSION_EXPIRY],
    enabled: !!documentId,
    queryFn: () => getDocumentCustomSessionExpiry(documentId),
  });
};

export default useDocumentCustomSessionExpiryQuery;

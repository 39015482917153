import React from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import i18n from '../../translations/i18n';

import en from './translations/en';
import de from './translations/de';

i18n.addResourceBundle('en', 'notFound', en);
i18n.addResourceBundle('de', 'notFound', de);

const StyledLink = styled(Link)`
  color: #067bc5;
  text-decoration: underline;

  &:hover {
    color: #067bc5;
    text-decoration: underline;
  }
`;

const TitleText = styled(Typography)<TypographyProps>(() => ({
  fontSize: '21px',
  fontWeight: '600',
  marginBottom: '24px',
  color: '#404040',
}));

const Message = styled(Typography)<TypographyProps>(() => ({
  fontSize: '14px',
  marginBottom: '24px',
  color: '#404040',
  textAlign: 'center',
}));

const NotFound: React.FC = () => {
  const { t } = useTranslation('notFound');

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <TitleText variant="h4" component={'h1'}>
        {t('title')}
      </TitleText>

      <Message variant="body1">{t('message')}</Message>

      <StyledLink to={window.location.origin}>{t('links.home.text')}</StyledLink>
    </Box>
  );
};

export default NotFound;

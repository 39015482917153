import React, { useEffect } from 'react';

const DocumentTitle: React.FC<{ title: string }> = ({ title }) => {
  useEffect(() => {
    document.title = `${title} – Conceptboard`;
  }, [title]);

  return null;
};

export default DocumentTitle;

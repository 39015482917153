import React from 'react';

import { Box, Typography, TypographyProps } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

import DocumentTitle from '../../features/shared/components/DocumentTitle';

import i18n from '../../translations/i18n';

import en from './translations/en';
import de from './translations/de';

i18n.addResourceBundle('en', 'errorPage', en);
i18n.addResourceBundle('de', 'errorPage', de);

const StyledLink = styled(Link)`
  color: #067bc5;
  text-decoration: underline;

  &:hover {
    color: #067bc5;
    text-decoration: underline;
  }
`;

const TitleText = styled(Typography)<TypographyProps>(() => ({
  fontSize: '21px',
  fontWeight: '600',
  marginBottom: '24px',
  color: '#404040',
}));

const Message = styled(Typography)<TypographyProps>(() => ({
  fontSize: '14px',
  marginBottom: '24px',
  color: '#404040',
  textAlign: 'center',
}));

const ErrorPage: React.FC = () => {
  const { t } = useTranslation('errorPage');

  return (
    <>
      <DocumentTitle title={t('title')} />

      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <TitleText variant="h4" component={'h1'}>
          {t('headline')}
        </TitleText>

        <Message variant="body1">{t('message')}</Message>
        <Message variant="body1">
          <Trans
            defaults={t('links.home.text')}
            components={[
              <StyledLink to={window.location.origin} key={'link1'} title={t('links.home.title')}></StyledLink>,
            ]}
          />
        </Message>
      </Box>
    </>
  );
};

export default ErrorPage;

import { get, put, del, post, patch } from '../apiClient';
import { UserInfoResponseType } from '../api.types';

/**
 * Load user information and basic team settings.
 * @return {object} Contains all user information and settings.
 */
export const getUserInfo = async (): Promise<UserInfoResponseType> => get(`/userinfo`);

/**
 * Load Team settings from the backend.
 * @param {string} teamId - Users Team ID.
 * @return {object} The team Settings object.
 */
export const getTeamSettings = async (teamId: string) => get(`/team/${teamId}/settings`);

/**
 * Update Team settings.
 * @param {string} teamId - Users Team ID.
 * @param {object} teamSettings - The new team Settings object.
 * @return {object} The status response from server.
 */
export const updateTeamSettings = async (teamId: string, teamSettings: any) =>
  put(`/team/${teamId}/settings`, teamSettings);

/**
 * Load team images (Logo or Banner) from the server.
 * @param {string} teamId - Users Team ID.
 * @param {string} imageType - The type of image to load, should be 'standard' or 'welcomebanner'.
 * @return {object} The status response from server.
 */
export const getTeamSettingsLogo = async (teamId: string, imageType: 'standard' | 'welcomebanner') =>
  get(`/team/${teamId}/settings/logo/${imageType}`);

/**
 * Update team images (Logo or Banner) on the server.
 * @param {string} teamId - Users Team ID.
 * @param {string} imageType - The tye of image to load, should be 'standard' or 'welcomebanner'.
 * @param {file} file - The new image file.
 * @return {object} The status response from server.
 */
export const updateTeamSettingsLogo = async (teamId: string, imageType: 'standard' | 'welcomebanner', file: any) => {
  const form = new FormData();
  form.append('type', imageType);
  form.append('file', file);

  return put(`/team/${teamId}/settings/logo`, form);
};

/**
 * Delete team images (Logo or Banner) on the server.
 * @param {string} teamId - Users Team ID.
 * @param {string} imageType - The type of image to load, should be 'standard' or 'welcomebanner'.
 * @return {object} The status response from server.
 */
export const deleteTeamSettingsLogo = async (teamId: string, imageType: 'standard' | 'welcomebanner') =>
  del(`/team/${teamId}/settings/logo/${imageType}`);

/**
 * Load user's team info.
 * @return {object} Contains user's team info.
 */
export const getTeamInfo = async (): Promise<any> => get(`/teaminfo`);

/**
 * Load user's team info.
 * @return {object} Contains user's team info.
 */
export const getUserGroups = async (userIds: string[]): Promise<any> =>
  get(
    `/jsonapi/user?filter=id=in=(${userIds.join(',')})&include=groups&fields%5Buser%5D=groups&fields%5BuserGroups%5D=groupname`
  );

export const getUserPreferences = async (keys: string[], userId: string) => {
  const preferenceKeys = keys.map((key) => `"${key}"`).join(',');

  return get(`/jsonapi/userPreferences/?filter=userid=="${userId}";key=in=(${preferenceKeys})`);
};

const userPreferences = {
  createRequestBody: (key: string, value: string | boolean, userId: string) => ({
    data: {
      type: 'userPreferences',
      id: `${userId}::${key}`,
      attributes: {
        value: typeof value === 'boolean' ? `${value}` : `"${value}"`,
      },
    },
  }),

  requestConfig: {
    headers: {
      'Content-Type': 'application/vnd.api+json',
    },
  },
};

export const postUserPreferences = async (key: string, value: string | boolean, userId: string) => {
  return await post(
    '/jsonapi/userPreferences',
    userPreferences.createRequestBody(key, value, userId),
    userPreferences.requestConfig
  );
};

export const patchUserPreferences = async (key: string, value: string | boolean, userId: string) => {
  return patch(
    `/jsonapi/userPreferences/${userId}::${key}`,
    userPreferences.createRequestBody(key, value, userId),
    userPreferences.requestConfig
  );
};

export const updateUserPreferences = async (key: string, value: string | boolean, userId: string) => {
  try {
    return await patchUserPreferences(key, value, userId);
  } catch (error: any) {
    // If PATCH fails with 404, fallback to POST (key doesn't exist yet)
    if (error.response?.status === 404) {
      return await postUserPreferences(key, value, userId);
    }

    throw error;
  }
};

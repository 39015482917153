/* eslint-disable autofix/no-console */
import { useEffect } from 'react';

const TAB_KEY = 'app_open_tabs';
const SESSION_KEY = `tab_${Date.now()}`; // Unique ID for each tab

function useTabTracking(onLastTabClosed: () => void) {
  console.log('🚀 useTabTracking started!');

  useEffect(() => {
    function getTabs() {
      return JSON.parse(localStorage.getItem(TAB_KEY) || '{}');
    }

    function updateTabCount(change: 'open' | 'close') {
      console.log('🚀 updateTabCount:', change);

      const tabs = getTabs();

      if (change === 'open') {
        tabs[SESSION_KEY] = true;
      } else if (change === 'close') {
        delete tabs[SESSION_KEY];
      }

      localStorage.setItem(TAB_KEY, JSON.stringify(tabs));

      // Run `onLastTabClosed` ONLY if this is a true tab closure, NOT a refresh
      if (change === 'close' && Object.keys(tabs).length === 0) {
        if (!sessionStorage.getItem('isReloading')) {
          onLastTabClosed();
        }
      }
    }

    // **Detect if this tab is being refreshed**
    const isRefresh = performance.getEntriesByType('navigation')[0]?.type === 'reload';

    if (isRefresh) {
      sessionStorage.setItem('isReloading', 'true');
    } else {
      sessionStorage.removeItem('isReloading');
    }

    // **Clear stale data when the first tab opens**
    if (!isRefresh && Object.keys(getTabs()).length === 0) {
      localStorage.setItem(TAB_KEY, JSON.stringify({}));
    }

    // Mark this tab as open
    updateTabCount('open');

    // Handle tab close
    const handleBeforeUnload = () => {
      sessionStorage.setItem('isReloading', 'true'); // Mark tab as reloading
      updateTabCount('close');
    };

    // Listen for `storage` event to detect when other tabs close
    const handleStorageChange = (event: any) => {
      if (event.key === TAB_KEY) {
        const tabs = JSON.parse(event.newValue || '{}');
        if (Object.keys(tabs).length === 0) {
          if (!sessionStorage.getItem('isReloading')) {
            onLastTabClosed();
          }
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('storage', handleStorageChange);
      updateTabCount('close'); // Ensure cleanup when unmounting
    };
  }, [onLastTabClosed]);
}

export default useTabTracking;

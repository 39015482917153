export default {
  PROJECTS: 'projects',
  USER_INFO: 'user-info',
  USER_INFO_LEGACY: 'user-info-legacy',
  USER_TASKS_LEGACY: 'user-tasks-legacy',
  MINIUSER_INFO_LEGACY: 'miniuser-info-legacy',
  NOTIFICATIONS: 'notifications',
  HEADER_NOTIFICATIONS: 'header-notifications',
  HEADER_NOTIFICATIONS_COUNT: 'header-notifications-count',
  USERS_WITH_ACCESS: 'users-with-access',
  COMPANY_INFO: 'company-info',
  ONLINE_USERS: 'online-users',
  PENDING_APPROVALS: 'pending-approvals',
  BOARDS_SEARCH: 'boards-search',
  CONTACTS_SEARCH: 'contacts-search',
  TEAM_MEMBER_SEARCH: 'team-member-search',
  TEAM_MEMBER_SEARCH_BY_EMAIL: 'team-member-search-by-email',
  TEAM_MEMBER_INFO: 'team-member-info',
  MY_COMPANY_LEGACY: 'my-company-legacy',
  LICENSE_INFO_LEGACY: 'license-info-legacy',
  LICENSE_STATUS_LEGACY: 'license-status-legacy',
  TEAM_INFO: 'team-info',
  ANNOUNCEMENTS: 'announcements',
  TEAM_SETTINGS_LEGACY: 'team-settings-legacy',
  FEATURE_FLAGS: 'feature-flags',
  FEATURE_FLAGS_BY_NAME: 'feature-flags-by-name',
  BANNER_INFO_LEGACY: 'banner-info-legacy',
  BOARDS_LIST_LEGACY: 'boards-list-legacy',
  SUBJECT_INFO_LEGACY: 'subject-info-legacy',
  BOARDS_INFO_LEGACY: 'boards-info-legacy',
  BOARS_INVITATIONS_LEGACY: 'board-invitations-legacy',
  BOARDS_PROJECT_INFO_LEGACY: 'boards-project-info-legacy',
  BOARD_APPROVAL_INFO_LEGACY: 'board-approval-info-legacy',
  PARTICIPANT_LIST_LEGACY: 'participant-list-legacy',
  PARTICIPANT_MINIUSER_LIST_LEGACY: 'participant-miniuser-list-legacy',
  PROJECT_PARTICIPANT_INFO_LEGACY: 'project-participant-info-legacy',
  BOARD_PARTICIPANT_INFO_LEGACY: 'board-participant-info-legacy',
  BOARD_ATTACHMENTS_LEGACY: 'board-attachments-legacy',
  BOARD_ACCESS_REQUEST_LEGACY: 'board-access-request-legacy',
  PERSONAL_CONTACTS_LEGACY: 'personal-contacts-legacy',
  TEAM_CONTACTS_LEGACY: 'team-contacts-legacy',
  REFERRAL_DATA_LEGACY: 'referral-data-legacy',
  APP_CONFIG_LEGACY: 'app-config-legacy',
  BLOCKED_USERS_LEGACY: 'blocked-users-legacy',
  COMPANY_JOIN_REQUEST_LEGACY: 'company-join-request-legacy',
  COMPANY_ACCESS_POLICY_LEGACY: 'company-access-policy-legacy',
  USER_GROUPS: 'user-groups',
  WEB_IMAGES_SEARCH: 'web-images-search',
  TEMPLATE_LIST: 'template-list',
  TEMPLATE_CATEGORY_LIST: 'template-category-list',
  TEMPLATE_LANGUAGE_LIST: 'template-language-list',
  TEAM_TEMPLATE_LIST: 'team-template-list',
  USER_PREFERENCES: 'user-preferences',
  TEAM_SETTINGS: 'team-settings',
  USER_CUSTOM_SESSION_EXPIRY: 'user-custom-session-expiry',
  DOCUMENT_CUSTOM_SESSION_EXPIRY: 'document-custom-session-expiry',
};

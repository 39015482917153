import { legacyApiClient } from '../apiClient';
import { IProject } from '../../layouts/privateLayout/drawer/components/projects/Projects.types';

import { InfoCallRequestPayloadType } from '../api.types';

import {
  LegacyUserInfoType,
  LegacyMyCompanyType,
  LegacyTeamSettingsType,
  LegacyAppInfoType,
  LegacyCompanyJoinRequestType,
  LegacyAccessControlType,
} from '../legacyApi.types';

import {
  DocumentAccessControlType,
  UserRoleType,
  BoardFilterType,
  ParticipantType,
} from '../../types/BoardsOverview.types';

export const parseInfoResponse = (text: string) => {
  if (typeof text != 'string') return null;
  // Remove Replace all occurrences of "\'" with just "'"
  const jsonText = text.slice(4).replace(/\\'/g, "'");
  return JSON.parse(jsonText);
};

export const getNotifications = async ({
  userId,
  pageParam,
  timelimit,
}: {
  userId: string;
  pageParam?: string;
  timelimit?: string;
}): Promise<any> => {
  // timelimit is used for checking new notifications and timeoffset for pagination
  const pageParamParam = pageParam ? `&timeoffset=${encodeURIComponent(pageParam)}` : '';
  const timelimitParam = timelimit ? `&timelimit=${encodeURIComponent(timelimit)}` : '';
  const url = `/api/v0_1/users/${userId}/notifications?extended=true${pageParamParam}${timelimitParam}`;
  const response = await legacyApiClient.get(url, {});

  return response.data;
};

export const postAlertsLastReadAt = async (userId: string): Promise<any> => {
  const response = await legacyApiClient.post(`/api/v0_1/users/${userId}/alertslastreadat`, {
    alertslastreadat: new Date().toISOString(),
  });

  return response.data;
};

export const postInfo = async (data: InfoCallRequestPayloadType[]) => {
  if (!data.length) return { list: [] };
  const response = await legacyApiClient.post('/__/info?extended=true', data, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/json',
    },
  });

  return parseInfoResponse(response.data);
};

export const getLegacyAppConfig = async (): Promise<LegacyAppInfoType> => {
  const response = await legacyApiClient.get('/api/v0_1/config/app?format=legacy', {
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const getLegacyBoardsList = async (pageNumber: number, filters: BoardFilterType) => {
  const response = await legacyApiClient.get('/__/ajax/query/list', {
    params: {
      ...filters,
      pageNumber,
    },
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  const parsedResponse = response.data.data;
  return parsedResponse;
};

export const getProjects = async (userId: string): Promise<IProject[]> => {
  const response = await legacyApiClient.get(`/api/v0_1/users/${userId}/portfolios`, {});

  return response.data.data;
};

export const postProject = async (portfolioTitle: string) => {
  const params = new URLSearchParams();
  params.append('portfolioTitle', portfolioTitle);

  const response = await legacyApiClient.post('/__/newproject', params, {
    headers: {
      'X-Is-Ajax-Call': true,
    },
  });

  return response.data;
};

export const copyDocument = async (documentId: string, includeHistory: string) => {
  const formData = new FormData();

  formData.append('documentId', documentId);
  formData.append('includeHistory', includeHistory);

  const response = await legacyApiClient.post('/__/copy', formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const renameDocument = async (type: 'document' | 'portfolio', documentId: string, newTitle: string) => {
  const formData = new FormData();

  formData.append('documentId', documentId);
  formData.append('title', newTitle);

  const response = await legacyApiClient.post(`/__/${type}/${documentId}/settings/title`, formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const addBoardToProject = async (documentId: string, projectId: string) => {
  const formData = new FormData();
  formData.append('documentId', documentId);
  formData.append('projectId', projectId);

  const response = await legacyApiClient.post('/__/addToProject', formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const removeBoardFromProject = async (documentId: string, projectId: string) => {
  const formData = new FormData();
  formData.append('documentId', documentId);

  const response = await legacyApiClient.post(`/__/portfolio/${projectId}/removedocument`, formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const changeBoardLockSetting = async (documentId: string, collaboration: boolean) => {
  const formData = new FormData();
  formData.append('collaboration', `${collaboration}`);

  const response = await legacyApiClient.post(`/__/document/${documentId}/settings/accesscontrol`, formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

interface IBoardNotificationSubscriptionPayload {
  userid: string;
  enabled: boolean;
  documentid?: string;
  portfolioid?: string;
}

export const changeNotificationSubscription = async (
  type: 'document' | 'portfolio',
  id: string,
  userId: string,
  enabled: boolean
) => {
  const requestPayload: IBoardNotificationSubscriptionPayload = { [`${type}id`]: id, userid: userId, enabled };

  const response = await legacyApiClient.post(`/api/v0_1/${type}s/${id}/notificationsubscriptions`, requestPayload);
  return response.data.data;
};

export const getUserProjectNonAdHoc = async (userId: string, projectId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/users/${userId}/portfolios/${projectId}/non_ad_hoc`);
  return response.data.data;
};

export const getUsersWithAccess = async (documentId: string, accessType: string) => {
  const response = await legacyApiClient.get(
    `/api/v0_1/documents/${documentId}/userswithaccess?accesstype=${accessType}`
  );

  return response.data.data;
};

export const getPendingApprovals = async (userId: string) => {
  const response = await legacyApiClient.get(
    `/api/v0_1/approvals?userid=${userId}&onlypending=true&onlycurrent=true&extended=true`
  );

  return response.data.data;
};

export const getBoardsSearch = async (search: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/documents/incrementalsearch/${search}?extended=true`);
  return response.data.data;
};

export const getLegacyUserInfo = async (): Promise<LegacyUserInfoType> => {
  const response = await legacyApiClient.get('/api/v0_1/users/me?format=complexlegacy');
  return response.data.data;
};

export const getLegacyMyCompany = async (): Promise<LegacyMyCompanyType> => {
  const response = await legacyApiClient.get(`/api/v0_1/users/me/company?format=legacy`);
  return response.data.data;
};

export const getLegacyCompanyAccessControlPolicy = async (
  companyId: string | undefined
): Promise<LegacyAccessControlType> => {
  const response = await legacyApiClient.get(`/api/v0_1/companys/${companyId}/accesscontrolpolicy`, {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const getJWTToken = async () => {
  const response = await legacyApiClient.get('/api/v0_1/templates/auth');
  return response.data.data.access_token;
};

export const getAnnouncements = async () => {
  const response = await legacyApiClient.get('/api/v0_1/announcements_v2');
  return response.data.data;
};

export const getFeatureFlags = async (userId: string): Promise<Record<string, boolean>> => {
  const response = await legacyApiClient.get(
    `/api/v0_1/users/${userId}/features/registration.selfsignup,zendesk,support,teamsettings.thirdparty,announcements,team.webimagessearch,team.userreport,board.original_file_download_disabled,contactspage,usergroup,failedloginblocking`
  );
  return response.data.data;
};

export const getFeatureFlagByName = async (
  userId: string,
  featureFlags: string[]
): Promise<Record<string, boolean>> => {
  const response = await legacyApiClient.get(`/api/v0_1/users/${userId}/features/${featureFlags.join(',')}`);
  return response.data.data;
};

export const getLegacyTeamSettings = async (companyId?: string): Promise<LegacyTeamSettingsType> => {
  const response = await legacyApiClient.get(`/api/v0_1/companys/${companyId}/teamsettings`);
  return response.data.data;
};

// XXX: once the legacy UI is gone, this should be replaced by userPreferences
export const getLegacyUserBannerStatus = async (userId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/userbannerstatus/${userId}`);
  return response.data.data;
};

// XXX: once the legacy UI is gone, this should be replaced by userPreferences
export const delLegacyUserBannerStatus = async (userId: string, bannerId: string) => {
  const response = await legacyApiClient.delete(`/api/v0_1/userbannerstatus/${userId}/${bannerId}`);

  return response.data.data;
};

export const getLegacyBoardApprovalInfo = async (approvalId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/approvals/${approvalId}`);
  return response.data.data[0];
};

export const legacyArchiveItem = async (type: 'document' | 'portfolio', id: string) => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.post(`/api/v0_1/${type}s/${id}/archive`);
  return response.data;
};

export const legacyUnarchiveItem = async (type: 'document' | 'portfolio', id: string) => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.post(`/api/v0_1/${type}s/${id}/unarchive`);
  return response.data;
};

export const legacyDeleteBoard = async (documentId: string) => {
  const formData = new FormData();
  formData.append('documentId', documentId);
  const response = await legacyApiClient.post('/__/delete', formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const legacyDeleteProject = async (projectId: string) => {
  const formData = new FormData();
  const response = await legacyApiClient.post(`/__/portfolio/${projectId}/delete`, formData, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return parseInfoResponse(response.data);
};

export const getParticipantList = async (type: 'document' | 'portfolio', id: string): Promise<ParticipantType[]> => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.get(`/api/v0_1/${type}s/${id}/participants`);
  return response.data.data;
};

export const getLegacyParticipantsAsMiniuser = async (type: 'document' | 'portfolio', id: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/${type}s/${id}/participantsasminiusers`);
  return response.data.data;
};

export const getLegacyBoardParticipants = async (documentId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/documents/${documentId}/participants`);
  return response.data.data;
};

export const getLegacyBoardParticipantById = async (documentId: string, userId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/documents/${documentId}/participants/${userId}`);
  return response.data.data;
};

export const getLegacyProjectParticipantById = async (projectId: string, userId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/portfolios/${projectId}/participants/${userId}`);
  return response.data.data;
};

export const getLegacyProjectParticipants = async (projectId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/portfolios/${projectId}/participants`);
  return response.data.data;
};

export const inviteUserToBoard = async (inviteData: any) => {
  const response = await legacyApiClient.post(`/api/v0_1/documentinvitations`, inviteData);
  return response.data.data;
};

export const deleteUserInvitationToBoard = async (inviteData: any) => {
  const response = await legacyApiClient.delete(`/api/v0_1/documentinvitations`, inviteData);
  return response.data.data;
};

export const inviteMultipleUsersToBoard = async (inviteList: any[]) => {
  const promises = inviteList.map((invite) => inviteUserToBoard(invite));
  await Promise.all(promises);
};

export const addUserToProject = async (inviteData: string, projectId: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/portfolios/${projectId}/userswithaccess`, inviteData);
  return response.data.data;
};

export const removeUserFromItem = async (type: 'document' | 'portfolio', userId: string, itemId: string) => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.delete(`/api/v0_1/${type}s/${itemId}/userswithaccess/${userId}`);
  return response.data.data;
};

export const changeItemOwner = async (type: 'document' | 'portfolio', id: string, userId: string) => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.post(`/api/v0_1/${type}s/${id}/userswithaccess/${userId}/changeowner`);
  return response.data.data;
};

export const changeUserRole = async (
  type: 'document' | 'portfolio',
  id: string,
  userId: string,
  accessType: UserRoleType
) => {
  // Please note the extra S on the url after the TYPE parameter.
  // This is necessary because the endpoint works on plural and our parameters are on the singular
  const response = await legacyApiClient.post(`/api/v0_1/${type}s/${id}/userswithaccess`, {
    accesstype: accessType,
    userid: userId,
  });
  return response.data.data;
};

export const addMultipleUsersToProject = async (inviteList: any[], projectId: string) => {
  const promises = inviteList.map((invite) => addUserToProject(invite, projectId));
  await Promise.all(promises);
};

export const copyProject = async (projectId: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/portfolios/${projectId}/actions/copy`);
  return response.data.data;
};

export const postDocumentAccessControl = async (documentId: string, accessControlData: DocumentAccessControlType) => {
  const response = await legacyApiClient.post(`/api/v0_1/documents/${documentId}/accesscontrol`, accessControlData);
  return response.data.data;
};

export const deleteBoardAttachment = async (documentId: string, attachmentId: string) => {
  const response = await legacyApiClient.delete(`/__/inbox/${documentId}/${attachmentId}`, {
    headers: {
      'X-Is-Ajax-Call': 'true',
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  });

  return response.data;
};

export const deleteAllBoardAttachments = async (documentId: string, attachmentIdList: string[]) => {
  const promises = attachmentIdList.map((attachmentId) => deleteBoardAttachment(documentId, attachmentId));
  await Promise.all(promises);
};

export const getBoardAccessRequest = async (documentId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/boardaccessrequest/${documentId}`);
  return response.data.data;
};

export const postAcceptBoardAccessRequest = async (accessRequestId: string, role: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/boardaccessrequest/${accessRequestId}/accept/${role}`);
  return response.data.data;
};

export const postDenyBoardAccessRequest = async (accessRequestId: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/boardaccessrequest/${accessRequestId}/decline`);
  return response.data.data;
};

// API ENDPOINTS CONTACTS
export const getTeamMemberForProject = async (searchString: string, projectId: string) => {
  const response = await legacyApiClient.post(
    `/api/v0_1/contacts/teammembers/${searchString}/filterforproject/${projectId}`,
    []
  );
  return response.data.data;
};

export const findTeamMemberByEmail = async (emailList: string[]) => {
  const response = await legacyApiClient.post(`/api/v0_1/contacts/teammembers/findbyemail`, { emails: emailList });
  return response.data.data;
};

export const getContactsForBoard = async (searchString: string, documentId: string) => {
  const response = await legacyApiClient.post(
    `/api/v0_1/contacts/personalcontacts/${searchString}/filter/${documentId}`,
    []
  );
  return response.data.data;
};

export const getTeamMemberForBoard = async (searchString: string, documentId: string) => {
  const response = await legacyApiClient.post(
    `/api/v0_1/contacts/teammembers/${searchString}/filterforboard/${documentId}`,
    []
  );
  return response.data.data;
};

export const getPersonalContacts = async () => {
  const response = await legacyApiClient.get('/api/v0_1/contacts/personalcontacts', {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const getTeamContacts = async () => {
  const response = await legacyApiClient.get('/api/v0_1/contacts/teammembers', {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const getCompanyJoinRequests = async (companyId?: string): Promise<LegacyCompanyJoinRequestType[]> => {
  const response = await legacyApiClient.get('/api/v0_1/companyjoinrequests', {
    params: { companyid: companyId },
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const createCompanyJoinRequest = async (requestPayload: Record<string, any>) => {
  const response = await legacyApiClient.post('/api/v0_1/companyjoinrequests', requestPayload);
  return response.data;
};

export const deleteCompanyJoinRequest = async (requestId: string) => {
  const response = await legacyApiClient.delete(`/api/v0_1/companyjoinrequests/${requestId}`, {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data;
};

export const addContact = async (requestPayload: Record<string, any>) => {
  const response = await legacyApiClient.post('/api/v0_1/contacts', requestPayload);
  return response.data;
};

export const deleteContact = async (contactId: string) => {
  const response = await legacyApiClient.delete(`/api/v0_1/contacts/contact/${contactId}`, {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const deleteUserInvitation = async (inviteId: string): Promise<{ inviteId: string }> => {
  const response = await legacyApiClient.delete(`/api/v0_1/contacts/company/${inviteId}`, {
    data: null,
    headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
  });
  return response.data.data;
};

export const updateCompanyUserRoles = async (contactId: string, companyId: string, roles: string[]) => {
  const response = await legacyApiClient.put(
    `/__/api/v0_1/company/${companyId}/user/${contactId}/roles`,
    JSON.stringify(roles),
    {
      headers: { 'X-Is-Ajax-Call': 'true', 'Content-Type': 'application/json' },
    }
  );
  return response.data.data;
};

export const getBlockedUsers = async (
  companyId?: string
): Promise<[{ contactDisplay: string; contactUserId: string }]> => {
  const response = await legacyApiClient.get(`/api/v0_1/companys/${companyId}/blockedusers`);
  return response.data.data;
};

export const deleteBlockedUsers = async (
  companyId: string,
  userId: string
): Promise<{ code: string; link: string }> => {
  const response = await legacyApiClient.delete(`/api/v0_1/companys/${companyId}/blockedusers/${userId}`);
  return response.data.data;
};

export const getLegacyReferralData = async (): Promise<{ code: string; link: string }> => {
  const response = await legacyApiClient.get('/api/v0_1/referraldata');
  return response.data.data.referralData;
};

export const getUserActivityReportCSVData = async (companyId?: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/companys/${companyId}/user-report?format=csv`, {
    responseType: 'text',
  });
  return response.data;
};

export const postLegacyCompanyReactivationRequest = async (companyId: string) => {
  const response = await legacyApiClient.post(`/api/v0_1/companys/${companyId}/reactivation`);
  return response.data;
};

export const getUserCustomSessionExpiry = async (userId: string) => {
  const response = await legacyApiClient.get(`/api/v0_1/custom-session-expiry/user/${userId}/max-ttl`);
  return response.data;
};

export const getDocumentCustomSessionExpiry = async (documentId: string | null) => {
  const response = await legacyApiClient.get(`/api/v0_1/custom-session-expiry/document/${documentId}/max-ttl`);
  return response.data;
};

import { toast, ToastOptions } from 'react-toastify';

/**
 * show alert message
 * @param alertType
 * @param message
 */
export const showAlert = (alertType: AlertType, message: string, options?: ToastOptions) => {
  toast[alertType](message, {
    position: options?.position,
    role: alertType === 'info' || alertType === 'success' ? 'status' : 'alert',
  });
};

type AlertType = 'info' | 'success' | 'warning' | 'error';

import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { GeneralHelper } from '../constants/GeneralHelper';
import { getCSRFToken, getCSRFTokenFromCookies } from '../utility/CommonUtility';
import { getJWTToken, parseInfoResponse } from './apiRoutes/legacyRoutes';
import * as yup from 'yup';

/************ CLIENT SETUP *****************/
export const client = axios.create({
  baseURL: GeneralHelper.API_URL ?? '/template-api',
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => handleRequestFailure(error, client)
);

// Conceptboard Interoperability API
export const cbInteropApi = axios.create({
  baseURL: '/cbapi',
  withCredentials: true,
});

cbInteropApi.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => handleRequestFailure(error, cbInteropApi)
);

// integrate API
export const integrateClient = axios.create();

integrateClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => handleRequestFailure(error, integrateClient)
);

// legacy API
export const legacyApiClient = axios.create({});

legacyApiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => handleRequestFailure(error, legacyApiClient)
);

type DataResult<T> =
  | {
      data: T;
      _kind: 'data';
    }
  | {
      error: yup.ValidationError;
      _kind: 'error';
    };

export const fromLegacyApiResponseBody: <T>(
  data: unknown,
  schema: yup.Schema<yup.Maybe<T>>
) => Promise<DataResult<T>> = async (data, schema) => {
  try {
    const { data: result } = await yup.object({ data: schema.required() }).validate(data, {
      abortEarly: false,
    });
    return { data: result, _kind: 'data' };
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      return { error, _kind: 'error' };
    }
    throw error;
  }
};

/************ End of api client setup *****************/

const redirectToLogin = () => {
  window.location.href = `${window.location.origin}/login?afterwardsUrl=${window.location.pathname}`;
};

const getErrorCode = (data: unknown) => {
  if (!data) {
    return '';
  }

  // parsing /__/info?extended=true
  if (typeof data === 'string') {
    try {
      const parsedData = parseInfoResponse(data);

      return parsedData.msgs[0]?.errorCode;
    } catch (e) {
      console.error('Error parsing data ', e);

      return '';
    }
  }

  // standard endpoints
  return (data as { errorCode?: string })?.errorCode;
};

const handleRequestFailure = async (error: any, apiClient: AxiosInstance) => {
  const originalRequest = error.config;

  // Check if the request has already been retried
  if (originalRequest?.csrfRetry || originalRequest?.jwtRetry) {
    redirectToLogin();
  }

  // Refresh CSRF token
  const errorCode = getErrorCode(error.response?.data);
  if (error.response?.status === 403 && errorCode === 'csrf.invalid') {
    originalRequest.csrfRetry = true;

    try {
      await fetch(`${window.location.origin}/api/v0_1/users/me?format=complexlegacy`);
      const csrfToken = await getCSRFTokenFromCookies();

      if (!csrfToken) {
        redirectToLogin();
      }

      legacyApiClient.defaults.headers.common['X-CB-CSRF'] = csrfToken;
      originalRequest.headers['X-CB-CSRF'] = csrfToken;

      return apiClient(originalRequest);
    } catch (err) {
      console.error('Failed to refresh CSRF token:', err);
      redirectToLogin();
    }
  }

  const isSpecificRequest =
    originalRequest.url?.includes('/api/v0_1/documents/') && originalRequest.url?.includes('/userswithaccess');

  // Refresh JWT token
  if (error.response?.status === 401 && !isSpecificRequest) {
    originalRequest.jwtRetry = true;

    try {
      const jwtToken = await getJWTToken();

      setApiAccessToken(jwtToken);
      originalRequest.headers['Authorization'] = `Bearer ${jwtToken}`;

      return apiClient(originalRequest);
    } catch (err) {
      console.error('Failed to refresh token:', err);
      redirectToLogin();
    }
  }

  /* eslint-disable @typescript-eslint/prefer-promise-reject-errors */ // TODO: investigate
  return Promise.reject(error);
};

/**
 * replace the existing 'Authorization' header with a Bearer Token Header with the given token
 *
 * @param token
 */
export const setApiAccessToken = (token: string) => {
  client.defaults.headers.common.Authorization = `Bearer ${token}`;
  integrateClient.defaults.headers.common.Authorization = `Bearer ${token}`;
  cbInteropApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};

/**
 * set the CSRF token from the cookie or logout
 */
export const setCSRFToken = async () => {
  const csrfToken = await getCSRFToken();

  if (!csrfToken) {
    window.location.href = `${window.location.origin}/login-redirect`;
  }

  legacyApiClient.defaults.headers.common['X-CB-CSRF'] = csrfToken;
};

/**
 * Generic request function for centralized error handling
 * @param {*} requestToExecute
 */
const request = async (requestToExecute: Promise<any>) => {
  return requestToExecute.then((response) => response.data);
};

/**
 * get method
 * @param {*} url
 * @param {*} config
 */
export const get = (url: string, config?: AxiosRequestConfig<any>) => request(client.get(url, config));

/**
 * post method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const post = (url: string, body: any, config?: AxiosRequestConfig<any>) =>
  request(client.post(url, body, config));

/**
 * put method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const put = (url: string, body: any, config?: AxiosRequestConfig<any>) => request(client.put(url, body, config));

/**
 * patch method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const patch = (url: string, body: any, config?: AxiosRequestConfig<any>) =>
  request(client.patch(url, body, config));

/**
 * delete method
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */
export const del = (url: string, config?: AxiosRequestConfig<any>) => request(client.delete(url, config));

/**
 * put method without Autherization header
 * @param {*} url
 * @param {*} body
 * @param {*} config
 */

export default {
  setApiAccessToken,
  get,
  post,
  put,
  patch,
  del,
};

export default {
  title: 'Error',
  headline: 'Oups…',
  message: 'There was an error. Please try again.',
  links: {
    home: {
      text: 'Or go <0>back to the start page</0>.',
      url: '/',
      title: 'Back to the start page',
    },
  },
  multierror: {
    headline: 'Is this a repeating error?',
    message:
      'If the problem occured multiple times you could help us to fix it. Please give a basic description what you were doing, when this page showed up. Thank you!',
  },
};

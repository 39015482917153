import { useQuery, QueryObserverOptions } from '@tanstack/react-query';
import { getUserInfo } from '../../api/apiRoutes/appRoutes';
import queryKeys from '../../constants/queryKeys';

type Options = Partial<QueryObserverOptions>;

const useUserInfoQuery = (options?: Options): any => {
  return useQuery({
    queryKey: [queryKeys.USER_INFO],
    queryFn: () => getUserInfo(),
    staleTime: Infinity,
    enabled: !!options?.enabled,
  });
};

export default useUserInfoQuery;
